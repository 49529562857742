import React, { SVGProps, useId } from 'react';

export const Prediction = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clipPath={`url(#${gradientId})`}>
        <path
          d="M9.91518 0.105378C8.31478 -0.323447 6.66977 0.626298 6.24094 2.2267L3.84064 11.1848C3.41181 12.7852 4.36156 14.4302 5.96196 14.859L9.82311 15.8936C11.4235 16.3224 13.0685 15.3727 13.4973 13.7723L15.8976 4.8142C16.3265 3.21381 15.3767 1.56879 13.7763 1.13997L9.91518 0.105378Z"
          fill="currentColor"
        />
        <path
          d="M2.22368 1.13999L5.12959 0.361359C4.85367 0.780213 4.63923 1.24979 4.5023 1.76084L2.10199 10.7189C2.01508 11.0433 1.96356 11.3688 1.94507 11.6913L0.102357 4.81423C-0.326469 3.21383 0.62328 1.56882 2.22368 1.13999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={`url(#${gradientId})`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
